import { isAvailable } from '@setapp/desktop-app-helper';
import BasePage from '../../components/base-page/base-page';
import Carousel from '../../components/carousel/carousel';
import defineCustomerReviewCardElement from '../../components/customer-review-card/customer-review-card';
import getInstallerUrl from '../../utils/installer-url/installer-url';
import VideoModal from '../../components/video-modal/video-modal';
import FlexArrow from '../../components/flex-arrow/flex-arrow';
import jump from '../../utils/jump';
import analytics, { events } from '../../../shared/analytics';
import breakpoints from '../../config/breakpoints';
import config from '../../../shared/config/config';

import './application.scss';


const STICKY_NAVIGATION_HEIGHT = 100;

class ApplicationPage extends BasePage {
  ui = {
    pageContainer: '.js-application-page',
    featureSection: '.js-application-page-features',
    seeMoreLink: '.js-jump-to-features',
    customerReviews: '.js-customer-reviews',
    jumpToCustomerReviews: '.js-jump-to-customer-reviews',
    webScreenshotSlider: '.js-web-screenshots',
    iosScreenshotSlider: '.js-ios-screenshots',
    macScreenshotSlider: '.js-mac-screenshots',
  };

  uiCollections = {
    ctaButtons: '[data-cta-button]',
    slidesContainers: '.js-carousel-slide-container',
    switches: '.js-switch',
  };

  events = {
    'click @uiCollections.ctaButtons': 'handleCtaButtonClick',
    'click @ui.seeMoreLink': 'scrollToFeaturesSection',
    'click @ui.jumpToCustomerReviews': 'scrollToCustomerReviewsSection',
    'click @uiCollections.switches': 'handleSwitchClick',
  };

  isUserLoggedIn = false;

  isSetappAvailable = false;

  shouldActivateIos = false;

  appId = null;

  iosAppId = null;

  appAutoLaunch = null;

  switchActiveAttr = 'data-active';

  onInit() {
    super.onInit();

    const arrow = new FlexArrow();
    arrow.render();

    this.appId = this.ui.pageContainer.dataset.appId || null;
    this.iosAppId = this.ui.pageContainer.dataset.iosAppId || null;
    this.appAutoLaunch = this.ui.pageContainer.dataset.appAutoLaunch || null;
    this.isUserLoggedIn = this.ui.pageContainer.dataset.isUserLoggedIn || false;
    this.shouldActivateIos = this.ui.pageContainer.dataset.shouldActivateIos || false;

    isAvailable({ onSuccess: this.handleDesktopAvailable, onError: this.handleDesktopNotAvailable });

    this.videoModal = new VideoModal();
    this.videoModal.init();

    if (this.ui.webScreenshotSlider) {
      this.webScreenshotsCarousel = new Carousel({
        rootElement: this.ui.webScreenshotSlider,
        loop: false,
        slidesPerPage: {
          [breakpoints.lg]: 2,
        },
      });
      this.webScreenshotsCarousel.init();
    }

    if (this.ui.macScreenshotSlider) {
      this.macScreenshotsCarousel = new Carousel({
        rootElement: this.ui.macScreenshotSlider,
        loop: false,
        slidesPerPage: {
          [breakpoints.lg]: 2,
        },
      });
      this.macScreenshotsCarousel.init();
    }

    if (this.ui.iosScreenshotSlider) {
      this.iosScreenshotsCarousel = new Carousel({
        rootElement: this.ui.iosScreenshotSlider,
        loop: false,
        slidesPerPage: {
          [breakpoints.xs]: 1.5,
          [breakpoints.sm]: 2.1,
          [breakpoints.md]: 3.1,
          [breakpoints.lg]: 4,
        },
      });
      this.iosScreenshotsCarousel.init();
    }

    // types from ApplicationPlatforms file
    this.carouselConfig = [
      { type: 'mac', htmlElement: this.ui.macScreenshotSlider, carousel: this.macScreenshotsCarousel },
      { type: 'web', htmlElement: this.ui.webScreenshotSlider, carousel: this.webScreenshotsCarousel },
      { type: 'ios_universal', htmlElement: this.ui.iosScreenshotSlider, carousel: this.iosScreenshotsCarousel },
    ];

    // Siema carousel adds style to slides by default
    // If we want slides to overflow screen, we need to change it
    this.uiCollections.slidesContainers.map((container) => (
      container.style.overflow = 'visible'
    ));

    defineCustomerReviewCardElement();
  }

  scrollToFeaturesSection = (e) => {
    e.preventDefault();
    jump(this.ui.featureSection, {
      offset: -1 * STICKY_NAVIGATION_HEIGHT,
    });
  };

  scrollToCustomerReviewsSection = () => {
    jump(this.ui.customerReviews, {
      offset: -1 * STICKY_NAVIGATION_HEIGHT,
    });
  };

  handleDesktopAvailable = () => {
    this.isSetappAvailable = true;

    if (this.appAutoLaunch) {
      this.launchSetapp(this.appId);
    }

    this.updateCtaButtons(this.uiCollections.ctaButtons);
  };

  handleDesktopNotAvailable = () => {
    this.isSetappAvailable = false;

    this.updateCtaButtons(this.uiCollections.ctaButtons);
  };

  handleCtaButtonClick = (e) => {
    e.preventDefault();
    const { eventLabel, ctaButtonActivateTitle } = e.target.dataset;

    if (this.shouldActivateIos && ctaButtonActivateTitle) {
      if (!this.isUserLoggedIn) {
        this.signupModal.open();

        return;
      }

      this.activateIos();
      analytics.trackEvent({
        ...events.CLICK_GET_IOS,
        eventLabel,
      });

      return;
    }

    if (this.isSetappAvailable) {
      // FIXME: SET-6311, if you press Launch app button on app page, Desktop won't handle web-only app correctly
      if (e.target.dataset.isReadyToSendApp) {
        window.open('https://getreadytosend.com/pages/setapp-login', '_blank', 'noopener,noreferrer');

        return;
      }
      // ENDFIXME

      this.launchSetapp(this.appId);

      analytics.trackEvent({
        ...events.LAUNCH_APP_BUTTON_CLICK,
        eventLabel,
      });

      return;
    }

    if (this.isUserLoggedIn) {
      window.location.assign(getInstallerUrl());
    } else {
      this.signupModal.open();
    }

    analytics.trackEvent({
      ...events.DOWNLOAD_APP_BUTTON_CLICK,
      eventLabel,
    });
  };

  handleSwitchClick = (e) => {
    const choosenSwitch = e.currentTarget;
    const { switchType } = choosenSwitch.dataset;

    const otherSwitches = this.uiCollections.switches.filter((item) => (
      item.dataset.switchType !== switchType
    ));

    const choosenSlider = this.carouselConfig.find((item) => item.type === switchType);
    const otherSliders = this.carouselConfig.filter((item) => (
      Boolean(item.htmlElement) && item.type !== switchType
    ));

    choosenSlider.htmlElement.classList.remove('d-none');
    otherSliders.forEach((slider) => slider.htmlElement.classList.add('d-none'));

    choosenSlider.carousel.handleResize();

    choosenSwitch.setAttribute(this.switchActiveAttr, '');
    otherSwitches.forEach((item) => item.removeAttribute(this.switchActiveAttr));
  };

  updateCtaButtons = (buttons) => {
    buttons.forEach((button) => {
      const {
        ctaButtonDownloadTitle,
        ctaButtonLaunchTitle,
        ctaButtonActivateTitle,
      } = button.dataset;

      if (this.shouldActivateIos && ctaButtonActivateTitle) {
        button.textContent = ctaButtonActivateTitle;
      } else if (this.isSetappAvailable) {
        button.textContent = ctaButtonLaunchTitle;
      } else {
        button.textContent = ctaButtonDownloadTitle;
      }
    });
  };

  launchSetapp = (appId) => {
    window.location.replace(`setapp://launch/${appId}`);
  };

  activateIos = () => {
    const accountHref = config.get('customerAccountPages.ios-apps');

    const activationUrl = `${accountHref}/activate/${this.iosAppId}`;

    window.location.assign(activationUrl);
  };
}

export default ApplicationPage;

ApplicationPage.initOnDomLoaded();
