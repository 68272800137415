import cookies from 'js-cookie';
import DesktopAppInstallerUrl from '@setapp/desktop-app-installer-url';
import config from '../../../shared/config/config';

const defaultUrl = config.get('desktopClient.installerUrl');
const GA_COOKIE_NAME = '_ga';

let trackingCid;

export function getCID() {
  let matches;
  const cidRegex = /^GA\d\.\d\.(.*)/;
  const gaCookie = cookies.get(GA_COOKIE_NAME);

  if (gaCookie) {
    matches = gaCookie.match(cidRegex);
  }

  if (!gaCookie || !matches) {
    trackingCid = undefined;

    return undefined;
  }

  [, trackingCid] = matches;

  return trackingCid;
}

export default function getInstallerUrl() {
  getCID();

  return new DesktopAppInstallerUrl(defaultUrl, { trackingCid }).toString();
}
