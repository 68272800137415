import jumpjs from 'jump.js';

let scrolling;
const end = () => {
  scrolling = false;
};

const jump = (to, options = {}) => {
  if (scrolling) {
    return;
  }
  scrolling = true;

  let callback;
  if (options.callback) {
    callback = () => {
      end();
      options.callback();
    };
  } else {
    callback = end;
  }

  jumpjs(to, { ...options, callback });
};

export default jump;
