class FlexArrow {
  constructor() {
    this.fromBlock = document.querySelector('.js-arrow-from-block');
    this.toBlock = document.querySelector('.js-arrow-to-block');
    this.canvas = document.querySelector('.js-arrow-canvas');
    this.circle = document.querySelector('.js-arrow-circle');
    this.arrowHead = document.querySelector('.js-arrow-head');
    this.arrowLine = document.querySelector('.js-arrow-line');
    this.controlPoint = document.querySelector('.js-arrow-control-point');
  }

  render() {
    if (!this.fromBlock || !this.toBlock || !this.canvas || !this.circle
      || !this.arrowHead || !this.arrowLine || !this.controlPoint) return false;

    const fromBlockCoords = this.fromBlock.getBoundingClientRect();
    const toBlockCoords = this.toBlock.getBoundingClientRect();
    const canvasCoords = this.canvas.getBoundingClientRect();
    const circleCoords = this.circle.getBoundingClientRect();

    const p1x = circleCoords.left - fromBlockCoords.left + circleCoords.width / 2;
    const p1y = circleCoords.height / 2;
    const p2x = Number(this.canvas.dataset.paddingLeft);
    const p2y = toBlockCoords.top - canvasCoords.top + toBlockCoords.height / 2;

    // mid-point of line:
    const mpx = (p2x + p1x) / 2;
    const mpy = (p2y + p1y) / 2;

    // offset to make line more angular
    const angleOffset = 200;

    // angle of perpendicular to line:
    const theta = Math.atan2(p2y - p1y + angleOffset, p2x - p1x) - Math.PI / 2;

    // distance of control point from mid-point of line:
    const offset = 200;

    // location of control point:
    const c1x = mpx + offset * Math.cos(theta);
    const c1y = mpy + offset * Math.sin(theta);

    this.controlPoint.setAttribute('cx', c1x);
    this.controlPoint.setAttribute('cy', c1y);

    // construct the command to draw a quadratic curve
    this.arrowHead.setAttribute('d', `m${p2x + 8} ${p2y - 6}-8 5.735 7.529 6.264`);
    this.arrowLine.setAttribute('d', `M${p1x} ${p1y}Q${c1x} ${c1y} ${p2x} ${p2y}`);

    this.canvas.setAttribute('height', p2y + 50); // 50px - is a safe space for arrow curves

    return true;
  }
}

export default FlexArrow;
