import { LitElement, html, unsafeCSS, css } from 'lit';
import classnames from 'classnames';

import config from '../../../shared/config/config';

import styles from './customer-review-card.component.scss';


class CustomerReviewCard extends LitElement {
  static properties = {
    isPositive: { type: Boolean },
    author: { type: String },
    date: { type: String },
    comment: { type: String },
    appVersion: { type: String },
    seeMoreLink: { type: String },
    seeMoreCaption: { type: String },
    forceDisplaySeeMoreButton: { type: Boolean },
    responseTitle: { type: String },
    responseComment: { type: String },
    responseDate: { type: String },

    shouldShowSeeMore: { state: true },
  };

  static styles = css`${unsafeCSS(styles.toString())}`;

  checkIfSeeMoreShouldBeShown() {
    if (this.seeMoreLink) {
      const commentNode = this.renderRoot.querySelector('.js-comment');
      this.shouldShowSeeMore = commentNode.scrollHeight > commentNode.clientHeight;
    }
  }

  firstUpdated() {
    if (this.forceDisplaySeeMoreButton) {
      this.shouldShowSeeMore = true;

      return;
    }

    this.checkIfSeeMoreShouldBeShown();
  }

  connectedCallback() {
    super.connectedCallback();

    if (this.forceDisplaySeeMoreButton) return;

    window.addEventListener('resize', () => this.checkIfSeeMoreShouldBeShown());
  }

  render() {
    const headerClasses = classnames('customer-review-card__header', 'customer-review-card__header-with-icon', {
      'customer-review-card__header-with-icon_positive': this.isPositive,
      'customer-review-card__header-with-icon_negative': !this.isPositive,
    });

    const commentClasses = classnames('customer-review-card__comment', 'js-comment', {
      'customer-review-card__comment_4-rows-only': this.seeMoreLink && !this.appVersion,
      'customer-review-card__comment_3-rows-only': this.seeMoreLink && this.appVersion,
    });

    return html`
      <div class="customer-review-card">
        <div class=${ headerClasses }>
          <div class="customer-review-card__header-caption">${ this.author }</div>
          <div class="customer-review-card__date">${ this.date }</div>
        </div>
        ${this.appVersion && html`<div class="customer-review-card__app-verison">${ this.appVersion }</div>`}
        <div class=${ commentClasses }>${ this.comment }</div>

        ${ this.responseTitle && this.responseComment && this.responseDate && html`
          <div class="customer-review-card__response">
            <div class="customer-review-card__header">
              <div class="text_md customer-review-card__header-caption">${ this.responseTitle }</div>
              <div class="customer-review-card__date">${ this.responseDate }</div>
            </div>
            <div class="customer-review-card__comment">${ this.responseComment }</div>
          </div>`}

        ${ this.shouldShowSeeMore ? html`
          <a href=${ this.seeMoreLink } class="customer-review-card__see-more">
            ${ this.seeMoreCaption }
          </a>` : null }
      </div>
    `;
  }
}

const defineCustomerReviewCardElement = () => {
  customElements.define(config.get('customElements').customerReviewCard, CustomerReviewCard);
};

export default defineCustomerReviewCardElement;
